exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-does-my-company-subscribe-tsx": () => import("./../../../src/pages/does-my-company-subscribe.tsx" /* webpackChunkName: "component---src-pages-does-my-company-subscribe-tsx" */),
  "component---src-pages-health-tsx": () => import("./../../../src/pages/health.tsx" /* webpackChunkName: "component---src-pages-health-tsx" */),
  "component---src-pages-privacy-forms-dns-tsx": () => import("./../../../src/pages/privacy/forms/dns.tsx" /* webpackChunkName: "component---src-pages-privacy-forms-dns-tsx" */),
  "component---src-pages-privacy-forms-dsar-tsx": () => import("./../../../src/pages/privacy/forms/dsar.tsx" /* webpackChunkName: "component---src-pages-privacy-forms-dsar-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-templates-author-post-archive-tsx": () => import("./../../../src/templates/author-post-archive.tsx" /* webpackChunkName: "component---src-templates-author-post-archive-tsx" */),
  "component---src-templates-blog-post-archive-tsx": () => import("./../../../src/templates/blog-post-archive.tsx" /* webpackChunkName: "component---src-templates-blog-post-archive-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-category-post-archive-tsx": () => import("./../../../src/templates/category-post-archive.tsx" /* webpackChunkName: "component---src-templates-category-post-archive-tsx" */),
  "component---src-templates-chart-cotd-tsx": () => import("./../../../src/templates/chart-cotd.tsx" /* webpackChunkName: "component---src-templates-chart-cotd-tsx" */),
  "component---src-templates-newsroom-tsx": () => import("./../../../src/templates/newsroom.tsx" /* webpackChunkName: "component---src-templates-newsroom-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-press-release-tsx": () => import("./../../../src/templates/press-release.tsx" /* webpackChunkName: "component---src-templates-press-release-tsx" */),
  "component---src-templates-tag-post-archive-tsx": () => import("./../../../src/templates/tag-post-archive.tsx" /* webpackChunkName: "component---src-templates-tag-post-archive-tsx" */)
}

